import { ExposureResult, ShowOptions } from '../common.types';
import { ExperimentsBag } from '@wix/wix-experiments';
import { ExposureService } from '../transport/exposure-service';
import { trackExposureResponse } from '../bi/track-exposure-response';
import { BiDefaultParams } from '@wix/user-feedback-common';
import { isExperimentEnabled } from './experiments-bag.utils';

type GetBiDefaultParamsDelegate = (
  categoryId: string | undefined,
) => BiDefaultParams;

export async function makeExposureRequest(
  exposureService: ExposureService,
  getBiDefaultParams: GetBiDefaultParamsDelegate,
  showOptions: ShowOptions,
): Promise<ExposureResult> {
  if (showOptions.disableExposure) {
    return {
      shouldExpose: true,
    };
  }

  const exposurePayload = await exposureService.getShouldAskQuestion(
    showOptions.questionId,
  );

  const { shouldExpose, categoryId, unExposedReason } = exposurePayload;
  const biDefaultParams = getBiDefaultParams(categoryId);

  trackExposureResponse({
    ...biDefaultParams,
    isAllowed: shouldExpose,
    ...(!shouldExpose ? { blockReason: unExposedReason } : {}),
  });

  return exposurePayload;
}

export function getShouldExpose(
  exposureRequest: Promise<ExposureResult>,
  experiments: ExperimentsBag,
): Promise<ExposureResult> {
  if (isExperimentEnabled(experiments, 'specs.user-feedback.DisableExposure')) {
    return Promise.resolve({
      shouldExpose: true,
    });
  }

  return exposureRequest;
}
