import Experiments, { ExperimentsBag } from '@wix/wix-experiments';
import { trackFeedbackCloseClick } from './bi/track-feedback-close-click';
import { trackLoadStarted } from './bi/track-load-started';
import { createTransport } from './transport/iframe-transport';
import {
  CloseEventResult,
  EnvironmentConfig,
  EventByType,
  ExposureResult,
  OpenerEvent,
  OpenerSharedOptions,
  ShowOptions,
  ShowOptionsComplete,
  UserFeedbackOpenerOptions,
} from './common.types';
import {
  BiDefaultParams,
  ClientError,
  ClientIncomingMessageTypes,
  ClientOutgoingMessageTypes,
  CloseReason,
  CloseResult,
  Config,
  OPENER_ERRORS,
  OpenerBreadcrumb,
  PageNames,
  getConfig,
} from '@wix/user-feedback-common';
import { EventEmitter, EventSubscription } from 'fbemitter';
import { CloseReasonType, OpenerEventTypes } from './constants';
import { ExposureService } from './transport/exposure-service';
import { promiseTimeout } from './utils/promise-timeout';
import {
  OpeningQuestionState,
  QuestionState,
  QuestionStateType,
  ShownQuestionState,
} from './utils/question-state';
import { FeedbackWindow } from './utils/iframe-state';
import { getSessionId } from './utils/session-id-provider';
import { validateForDevMode } from './validators/dev-mode-validation';
import { createDataForQuestionOpen } from './utils/opener.utils';
import { getShouldExpose, makeExposureRequest } from './utils/should-expose';
import {
  MutedError,
  mutePromiseError,
  unmutePromiseError,
} from './utils/mute-promise-error';
import { OpenQuestionMessageParams } from './user-feedback-opener.types';
import { IFrameWrapper } from './iframe/iframe';
import {
  captureBreadcrumb,
  getBreadcrumbs,
  resetBreadcrumbs,
} from './utils/loggers/breadcrumbs-logger';
import {
  createErrorLogger,
  OpenerErrorLogger,
} from './utils/loggers/error-logger';
import { trackIFramePreload } from './bi/track-iframe-preload';
import { trackShowQuestionMethodCalled } from './bi/track-show-question-method-called';
import { trackDestroyMethodCalled } from './bi/track-destroy-method-called';
import { trackIFrameReadyMessageReceived } from './bi/track-iframe-ready-message-received';
import { trackIFrameHidden } from './bi/track-iframe-hidden';
import { trackIFrameLayoutReadyMessageReceived } from './bi/track-iframe-layout-ready-message-received';
import { trackIFrameShown } from './bi/track-iframe-shown';
import {
  closeReasonToCloseReasonType,
  trackCloseFrameMessageReceived,
} from './bi/track-close-frame-message-recieved';
import { assertSharedOptions } from './utils/shared-options.utils';

const defaultEnvConfig: EnvironmentConfig = { debug: false };

function getEnvConfig(envConfig: EnvironmentConfig): Config {
  const { debug } = envConfig;

  if (!debug) {
    return getConfig();
  }

  if (debug === true) {
    return getConfig(true);
  }

  return { ...getConfig(true), ...debug };
}

/**
 * This is opener!!!
 */
export class UserFeedbackOpener {
  private readonly options: UserFeedbackOpenerOptions;
  private readonly config: Config;
  private readonly messagesEmitter: EventEmitter = new EventEmitter();
  private readonly exposureService: ExposureService;
  private currentQuestion: QuestionState = { state: QuestionStateType.None };
  private feedbackWindow: FeedbackWindow | undefined = undefined;
  private readonly experiments: Experiments;
  private readonly errorLogger: OpenerErrorLogger;

  constructor(
    options: UserFeedbackOpenerOptions,
    envConfig: EnvironmentConfig = defaultEnvConfig,
  ) {
    this.errorLogger = createErrorLogger();
    this.options = options;
    this.config = getEnvConfig(envConfig);
    this.exposureService = new ExposureService(
      this.config,
      this.options.siteToken,
    );
    this.experiments = new Experiments();
  }

  private readonly loadExperimentsBag = (): Promise<ExperimentsBag> => {
    return this.experiments.ready().then(() => {
      return this.experiments.all();
    });
  };

  private readonly loadExperimentsBagWithLogging = (
    questionState: OpeningQuestionState | ShownQuestionState,
  ): Promise<ExperimentsBag> => {
    const defaultBiParams = this.getBiDefaultParams(questionState)(undefined);

    return this.loadExperimentsBag().catch(
      this.errorLogger
        .withDefaultBi(defaultBiParams)
        .createLoggingHandler(OPENER_ERRORS.opener.experimentLoad),
    );
  };

  private getFeedbackConfigUrl(): string {
    return this.config.AI_FEEDBACK_PAGE_URL;
  }

  private getURL(): string {
    const feedbackConfigUrl = this.getFeedbackConfigUrl();

    const commonConfigValue = window.commonConfig
      ? encodeURIComponent(JSON.stringify(window.commonConfig))
      : undefined;

    const commonConfigParam = commonConfigValue
      ? `${
          feedbackConfigUrl.includes('?') ? '&' : '?'
        }commonConfig=${commonConfigValue}`
      : '';

    return `${feedbackConfigUrl}${commonConfigParam}`;
  }

  private init(): FeedbackWindow {
    captureBreadcrumb({
      message: `Opener is initializing iframe and transport`,
    });

    const iframe = new IFrameWrapper(
      this.getURL(),
      'Some title',
      this.options.appendTo || document.body,
    );

    const transport = createTransport(iframe.wrappedIFrame, {
      targetOrigin: this.config.AI_FEEDBACK_PAGE_URL,
    });

    document.addEventListener(
      'consentPolicyChanged',
      this.handleConsentPolicyChanged,
    );

    const feedbackWindow = {
      iframe,
      transport,
      loadPromise: mutePromiseError(
        promiseTimeout<void>(
          10000,
          () =>
            new Error(
              `Iframe haven't sent READY message within given timeout.`,
            ),
          transport.waitForMessage(ClientOutgoingMessageTypes.Ready),
        ),
      ),
    };

    // tslint:disable-next-line:no-floating-promises
    this.experiments.load('user-feedback');

    transport.addListener(ClientOutgoingMessageTypes.Ready, () => {
      captureBreadcrumb({
        message: `Opener received 'Ready' message (iframe reload handler)`,
      });

      // This is only for iframe reloading, so track only Shown state
      if (this.currentQuestion.state !== QuestionStateType.Shown) {
        return;
      }

      trackIFrameReadyMessageReceived(
        this.getBiDefaultParams(this.currentQuestion)(undefined),
      );

      if (!this.feedbackWindow) {
        // TODO: this is error situation. Emit error and reload iframe.
        return;
      }

      captureBreadcrumb({
        message: `Opener hides iframe (iframe reload handler)`,
      });

      this.feedbackWindow.iframe.hide();

      this.currentQuestion = {
        state: QuestionStateType.Opening,
        showOptions: this.currentQuestion.showOptions,
        showOptionsComplete: this.currentQuestion.showOptionsComplete,
        sessionId: this.currentQuestion.sessionId,
      };

      captureBreadcrumb({
        message: `Opener sends 'OpenQuestion' message (iframe reload handler)`,
        data: this.currentQuestion.showOptions,
      });

      transport.sendMessage({
        type: ClientIncomingMessageTypes.OpenQuestion,
        data: createDataForQuestionOpen(
          this.currentQuestion.showOptionsComplete,
          {
            config: this.config,
            options: this.options,
            sessionId: this.currentQuestion.sessionId,
            experiments: this.experiments.all(),
          },
        ),
      });
    });

    transport.addListener(ClientOutgoingMessageTypes.LayoutIsReady, () => {
      captureBreadcrumb({
        message: `Opener received 'LayoutIsReady' message`,
      });

      if (this.currentQuestion.state === QuestionStateType.None) {
        return;
      }

      const defaultBiParams = this.getBiDefaultParams(this.currentQuestion)(
        undefined,
      );
      trackIFrameLayoutReadyMessageReceived(defaultBiParams);

      if (!this.feedbackWindow) {
        // TODO: this is error situation. Emit error and reload iframe.
        this.errorLogger
          .withDefaultBi(defaultBiParams)
          .log(OPENER_ERRORS.openerHandlers.layoutIsReadyHasNoFeedbackWindow);
        return;
      }

      const {
        showOptions,
        showOptionsComplete,
        sessionId,
      } = this.currentQuestion;
      this.currentQuestion = {
        state: QuestionStateType.Shown,
        showOptions,
        showOptionsComplete,
        sessionId,
      };

      captureBreadcrumb({
        message: `Opener shows iframe`,
        data: showOptions,
      });

      this.sendBeforeShowMessage(this.feedbackWindow);
      trackIFrameShown(defaultBiParams);

      iframe.show();

      this.emitMessage({
        type: OpenerEventTypes.Opened,
        showOptions,
      });
    });

    transport.addListener(
      ClientOutgoingMessageTypes.LayoutMountError,
      (error: ClientError) => {
        captureBreadcrumb({
          message: `Opener received 'LayoutMountError' message`,
          data: {
            error,
          },
        });

        if (this.currentQuestion.state !== QuestionStateType.Opening) {
          return;
        }

        const defaultBiParams = this.getBiDefaultParams(this.currentQuestion)(
          undefined,
        );

        this.errorLogger
          .withDefaultBi(defaultBiParams)
          .log(OPENER_ERRORS.openerHandlers.layoutMountError, {
            reason: error,
          });

        this.emitErrorAndResetState(this.currentQuestion.showOptions)(error);
      },
    );

    transport.addListener(
      ClientOutgoingMessageTypes.CloseFrame,
      (result: CloseResult) => {
        captureBreadcrumb({
          message: `Opener received 'CloseFrame' message`,
          data: {
            result,
          },
        });

        if (this.currentQuestion.state !== QuestionStateType.None) {
          const defaultBiParams = this.getBiDefaultParams(this.currentQuestion)(
            undefined,
          );
          trackCloseFrameMessageReceived({
            ...defaultBiParams,
            closeReason: closeReasonToCloseReasonType(result.type),
          });
        }

        const showOptions =
          this.currentQuestion.state === QuestionStateType.None
            ? undefined
            : this.currentQuestion;
        const trackCloseBi = trackFeedbackCloseClick(
          showOptions
            ? this.getBiDefaultParams(showOptions)(undefined)
            : undefined,
        );

        switch (result.type) {
          case CloseReason.FinishedFlow:
            if (Boolean(result.flowConfig && result.flowConfig.thankYouPage)) {
              trackCloseBi(PageNames.ThankYou);
            }
            this.hideByClose({
              closeReason: CloseReasonType.FinishedFlow,
              questionResult: result.data,
            });
            return;

          case CloseReason.Canceled:
            trackCloseBi(PageNames.Question);
            this.hideByClose({
              closeReason: CloseReasonType.Canceled,
            });
            return;

          case CloseReason.CanceledDueToError:
            trackCloseBi(PageNames.Error);

            if (this.currentQuestion.state !== QuestionStateType.None) {
              this.emitMessage({
                type: OpenerEventTypes.Closed,
                showOptions: this.currentQuestion.showOptions,
                closeEventPayload: {
                  closeReason: CloseReasonType.CanceledDueToError,
                },
              });
            }

            /* When popup is closed due to error it's better to destroy it, since there a chance that
             * user-feedback-client is in erroneous state and error persist if we open same feedback popup again. */
            this.destroy();
            return;

          default:
            this.hideByClose({
              closeReason: CloseReasonType.Canceled,
            });
            return;
        }
      },
    );

    return feedbackWindow;
  }

  private readonly handleConsentPolicyChanged = () => {
    if (!window.consentPolicyManager || !this.feedbackWindow) {
      return;
    }

    this.feedbackWindow.transport.sendMessage({
      type: ClientIncomingMessageTypes.ConsentPolicyChanged,
      data: window.consentPolicyManager.getCurrentConsentPolicy().policy,
    });
  };

  private hideByClose(closeResult: CloseEventResult) {
    if (
      !this.feedbackWindow ||
      this.currentQuestion.state === QuestionStateType.None
    ) {
      return;
    }

    trackIFrameHidden(this.getBiDefaultParams(this.currentQuestion)(undefined));
    captureBreadcrumb({
      message: `Opener hides iframe`,
    });

    this.feedbackWindow.iframe.hide();

    const { showOptions } = this.currentQuestion;
    this.currentQuestion = { state: QuestionStateType.None };

    this.emitMessage({
      type: OpenerEventTypes.Closed,
      showOptions,
      closeEventPayload: closeResult,
    });
  }

  private emitMessage(message: OpenerEvent) {
    this.messagesEmitter.emit(message.type, message);
  }

  private readonly emitErrorAndResetState = (showOptions: ShowOptions) => (
    e: any,
  ) => {
    this.currentQuestion = { state: QuestionStateType.None };
    this.emitMessage({
      type: OpenerEventTypes.Error,
      showOptions,
      error: e,
    });
  };

  private readonly sendOpenQuestionMessage = ({
    feedbackWindow,
    showOptions,
    sessionId,
    startOpeningTime,
    categoryId,
    experimentsBag,
  }: OpenQuestionMessageParams) => {
    captureBreadcrumb({
      message: `Opener sends 'OpenQuestion' message`,
      data: showOptions,
    });
    feedbackWindow.transport.sendMessage({
      type: ClientIncomingMessageTypes.OpenQuestion,
      data: createDataForQuestionOpen(showOptions, {
        config: this.config,
        options: this.options,
        sessionId,
        startOpeningTime,
        categoryId,
        experiments: experimentsBag,
      }),
    });
  };

  private readonly sendBeforeShowMessage = (feedbackWindow: FeedbackWindow) => {
    const openerBreadcrumbs: OpenerBreadcrumb[] = getBreadcrumbs();
    resetBreadcrumbs();
    feedbackWindow.transport.sendMessage({
      type: ClientIncomingMessageTypes.BeforeShowIframe,
      data: {
        openerBreadcrumbs,
      },
    });
  };

  preload() {
    trackIFramePreload({
      metasiteId: this.options.metasiteId,
      origin: this.options.origin,
      sessionId:
        this.currentQuestion.state === QuestionStateType.None
          ? 'no-session'
          : this.currentQuestion.sessionId,
    });
    captureBreadcrumb({
      message: `'preload' was called`,
    });

    if (!this.feedbackWindow) {
      this.feedbackWindow = this.init();
    }
  }

  closeFeedbackWindow() {
    captureBreadcrumb({
      message: `'closeFeedbackWindow' was called`,
    });

    this.hideByClose({ closeReason: CloseReasonType.ClosedByOpener });
  }

  destroy() {
    trackDestroyMethodCalled({
      metasiteId: this.options.metasiteId,
      origin: this.options.origin,
      sessionId:
        this.currentQuestion.state === QuestionStateType.None
          ? 'no-session'
          : this.currentQuestion.sessionId,
    });
    captureBreadcrumb({
      message: `'destroy' was called`,
    });

    this.currentQuestion = { state: QuestionStateType.None };
    if (!this.feedbackWindow) {
      return;
    }

    const { iframe, transport } = this.feedbackWindow;

    iframe.destroy();

    document.removeEventListener(
      'consentPolicyChanged',
      this.handleConsentPolicyChanged,
    );

    try {
      transport.destroy();
    } finally {
      this.feedbackWindow = undefined;
    }
  }

  addListener<T extends OpenerEventTypes>(
    eventType: T,
    listener: (data: EventByType<T>) => void,
  ): EventSubscription {
    captureBreadcrumb({
      message: `'addListener' was called`,
    });

    return this.messagesEmitter.addListener(eventType, listener);
  }

  private readonly getBiDefaultParams = ({
    sessionId,
    showOptionsComplete,
  }: OpeningQuestionState | ShownQuestionState) => (
    categoryId: string | undefined,
  ): BiDefaultParams => {
    const { questionId, triggerEvent, initiator } = showOptionsComplete;

    return {
      sessionId,
      initiatorName: initiator,
      metasiteId: this.options.metasiteId,
      questionId,
      trigger: triggerEvent,
      origin: this.options.origin,
      ...(categoryId ? { categoryId } : {}),
    };
  };

  private readonly unmuteIframePromiseWithLogging = (
    loadPromise: Promise<MutedError<void>>,
    questionState: OpeningQuestionState | ShownQuestionState,
  ) => {
    return unmutePromiseError(loadPromise).catch(
      this.errorLogger
        .withDefaultBi(this.getBiDefaultParams(questionState)(undefined))
        .createLoggingHandler(OPENER_ERRORS.opener.iframeLoad),
    );
  };

  private readonly getShouldExposeWithLogging = (
    exposureRequest: Promise<MutedError<ExposureResult>>,
    experiments: ExperimentsBag,
    questionState: OpeningQuestionState | ShownQuestionState,
  ) => {
    return getShouldExpose(
      unmutePromiseError(exposureRequest),
      experiments,
    ).catch(
      this.errorLogger
        .withDefaultBi(this.getBiDefaultParams(questionState)(undefined))
        .createLoggingHandler(OPENER_ERRORS.opener.iframeLoad),
    );
  };

  showQuestion(showOptions: ShowOptions): void {
    const sharedOptions: OpenerSharedOptions = assertSharedOptions({
      ...this.options,
      ...showOptions,
    });
    const error = validateForDevMode(this.options, showOptions);
    if (error) {
      this.emitMessage(error);
      return;
    }
    const showOptionsComplete: ShowOptionsComplete = {
      ...showOptions,
      ...sharedOptions,
    };

    // If have active question emit error
    if (this.currentQuestion.state !== QuestionStateType.None) {
      this.emitMessage({
        type: OpenerEventTypes.Error,
        showOptions,
        error: new Error(
          'Cannot show question while another question is being opened. Try to close active question before showing new one.',
        ),
      });

      return;
    }

    const startOpeningTime = new Date().getTime();
    const questionOpeningState: OpeningQuestionState = {
      state: QuestionStateType.Opening,
      showOptions,
      showOptionsComplete,
      sessionId: getSessionId(),
    };
    this.currentQuestion = questionOpeningState;

    trackShowQuestionMethodCalled(
      this.getBiDefaultParams(questionOpeningState)(undefined),
    );
    captureBreadcrumb({
      message: `'showQuestion' was called`,
      data: showOptions,
    });

    if (process.env.NODE_ENV === 'development' && !this.feedbackWindow) {
      console.error(
        "It's highly recommended to use `opener.preload()` method to speed up displaying of Feedback popup",
      );
    }

    // Load iframe, exposure and experiments
    const feedbackWindow =
      this.feedbackWindow || (this.feedbackWindow = this.init());
    const exposureRequest = mutePromiseError(
      makeExposureRequest(
        this.exposureService,
        this.getBiDefaultParams(questionOpeningState),
        showOptions,
      ),
    );

    // This is the main open flow
    Promise.all([
      this.loadExperimentsBagWithLogging(questionOpeningState),
      this.unmuteIframePromiseWithLogging(
        this.feedbackWindow.loadPromise,
        questionOpeningState,
      ),
    ])
      .then(([experimentsBag]: [ExperimentsBag, void]) => {
        // Now unmute exposure request
        return this.getShouldExposeWithLogging(
          exposureRequest,
          experimentsBag,
          questionOpeningState,
        ).then(({ shouldExpose, categoryId }) => {
          captureBreadcrumb({
            message: `'showQuestion' function received all required data`,
            data: {
              shouldExpose,
            },
          });

          if (!shouldExpose) {
            this.currentQuestion = { state: QuestionStateType.None };
            // TODO: emit message that the question will not be shown
            return;
          }

          trackLoadStarted({
            ...this.getBiDefaultParams(questionOpeningState)(categoryId),
          });

          this.sendOpenQuestionMessage({
            feedbackWindow,
            sessionId: questionOpeningState.sessionId,
            categoryId,
            experimentsBag,
            showOptions: showOptionsComplete,
            startOpeningTime,
          });
        });
      })
      .catch(this.emitErrorAndResetState(showOptions));
  }
}
