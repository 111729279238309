import { OpenerSharedOptions } from '../common.types';

export function assertSharedOptions({
  initiator,
}: Partial<OpenerSharedOptions>): OpenerSharedOptions {
  if (initiator === undefined) {
    throw new Error(
      '"initiator" parameter is required. Pass it via constructor or via showQuestion method',
    );
  }

  return {
    initiator,
  };
}
