import {
  BiDefaultParams,
  ErrorDefinition,
  ErrorHandler,
  ErrorLogger,
  ErrorLoggerConfigurator,
  getErrorBiParams,
  LogParams,
} from '@wix/user-feedback-common';
import { biErrorLogger } from '../../bi/bi-error-logger';

export interface OpenerErrorLogger {
  withDefaultBi(defaultParams: BiDefaultParams): ErrorLogger;
}

export const createErrorLogger = (): OpenerErrorLogger => {
  const configurator = new ErrorLoggerConfigurator();

  return {
    withDefaultBi(defaultParams: BiDefaultParams) {
      const biErrorHandler: ErrorHandler = (
        error: ErrorDefinition,
        params?: LogParams,
      ) => {
        // tslint:disable-next-line no-floating-promises
        biErrorLogger.log({
          ...defaultParams,
          ...getErrorBiParams(error, params),
        });
      };

      return configurator.addErrorHandler(biErrorHandler).createLogger();
    },
  };
};
