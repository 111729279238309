import { BiDefaultParams, PageNames } from '@wix/user-feedback-common';
import { biLogger } from './bi-logger';

// TODO move tracking to client.
export const trackFeedbackCloseClick = (
  defaultParams: BiDefaultParams | undefined,
) => (pageName: PageNames): void => {
  if (!defaultParams) {
    return;
  }

  // tslint:disable-next-line no-floating-promises
  biLogger.log({
    ...defaultParams,
    pageName,
    evid: 803,
  });
};
