import { biLogger } from './bi-logger';
import { BiDefaultParams } from '@wix/user-feedback-common';

export const trackShowQuestionMethodCalled = (params: BiDefaultParams) => {
  // tslint:disable-next-line no-floating-promises
  biLogger.log({
    ...params,
    evid: 840,
  });
};
