import { biLogger } from './bi-logger';

interface BiIFramePreloadParams {
  metasiteId: string;
  origin: string;
  sessionId: string;
}

export const trackIFramePreload = (params: BiIFramePreloadParams) => {
  // tslint:disable-next-line no-floating-promises
  biLogger.log({
    ...params,
    evid: 838,
  });
};
