import { biLogger } from './bi-logger';

export interface BiDestroyMethodCalledParams {
  metasiteId: string;
  origin: string;
  sessionId: string;
}

export const trackDestroyMethodCalled = (
  params: BiDestroyMethodCalledParams,
) => {
  // tslint:disable-next-line no-floating-promises
  biLogger.log({
    ...params,
    evid: 841,
  });
};
