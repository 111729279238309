export function promiseTimeout<R>(
  ms,
  errorFactory: () => Error,
  promise,
): Promise<R> {
  return new Promise<R>(function (resolve, reject) {
    // create a timeout to reject promise if not resolved
    const timer = setTimeout(function () {
      reject(errorFactory());
    }, ms);

    promise
      .then(function (res) {
        clearTimeout(timer);
        resolve(res);
      })
      .catch(function (err) {
        clearTimeout(timer);
        reject(err);
      });
  });
}
