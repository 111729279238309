import axios from 'axios';
import { Config } from '@wix/user-feedback-common';

export interface ExposurePayload {
  featureId: string;
  shouldExpose: boolean;
  unExposedReason: string;

  categoryId?: string;
}

export class ExposureService {
  private readonly transport;
  private readonly siteToken;

  constructor(config: Config, siteToken: string) {
    this.siteToken = siteToken;
    this.transport = axios.create({
      withCredentials: true,
      baseURL: config.EXPOSURE_SERVICE_BASE_URL,
    });
  }

  getShouldAskQuestion(questionId: string): Promise<ExposurePayload> {
    return this.transport
      .get(`f/${questionId}?siteToken=${this.siteToken}`)
      .then((response) => response.data.payload)
      .catch((e) => {
        if (
          e?.response?.status === 401 &&
          window.location.protocol === 'http:'
        ) {
          console.warn(
            'ATTENTION! If server complains on missing xsrf token (401 error) this is most probably because you are opening site over non-secure connection.\n' +
              'Find more information on how this can be fixed — in comment section below code where this warning is called',
          );
          // Support of HTTPS protocol locally should be implemented in this task: https://jira.wixpress.com/browse/AF-237
          // Meanwhile as a workaround you can uncheck `secure` flag for `XSRF-TOKEN` cookie
          // in Application —> Cookies tab in dev tools
        }

        throw e;
      });
  }
}
