import {
  ShowOptionsComplete,
  UserFeedbackOpenerOptions,
} from '../common.types';
import {
  Config,
  ShowQuestionOpenerParams,
  ShowQuestionPayload,
} from '@wix/user-feedback-common';
import { ExperimentsBag } from '@wix/wix-experiments';

export interface CreateDataForQuestionOpenParams {
  config: Config;
  options: UserFeedbackOpenerOptions;
  sessionId: string;
  startOpeningTime?: number;
  categoryId?: string;
  experiments: ExperimentsBag;
}

function buildPayload(
  options: ShowOptionsComplete,
  params: CreateDataForQuestionOpenParams,
): ShowQuestionPayload {
  const {
    config: { FEEDBACK_SERVICE_BASE_URL, EXPOSURE_SERVICE_BASE_URL },
    options: { locale, siteToken, metasiteId, origin },
    sessionId,
    categoryId,
  } = params;
  const {
    customContext,
    noDontShowAgain,
    questionId,
    triggerEvent,
    feedbackModel,
    thankYouPage,
    initiator,
    documentationLink,
  } = options;

  return {
    windowConfig: {
      locale,
      disableDontShowAgain: noDontShowAgain,
      documentationLink,
    },
    flowConfig: {
      thankYouPage,
    },
    questionContext: {
      categoryId,
      customContext,
      initiator,
      metasiteId,
      origin,
      questionId,
      sessionId,
      siteToken,
      triggerEvent,
    },
    questionModel: feedbackModel,
    topologyConfig: {
      exposureServiceBaseUrl: EXPOSURE_SERVICE_BASE_URL,
      feedbackServiceBaseUrl: FEEDBACK_SERVICE_BASE_URL,
    },
  };
}

export function createDataForQuestionOpen(
  showOptions: ShowOptionsComplete,
  params: CreateDataForQuestionOpenParams,
): ShowQuestionOpenerParams {
  const { startOpeningTime = new Date().getTime(), experiments } = params;

  return {
    meta: {
      startOpeningTime,
      experiments,
    },
    payload: buildPayload(showOptions, params),
  };
}

export function parseMetasiteId(metasiteId: string) {
  return metasiteId.replace(/^_template_/, '');
}
