import { EventEmitter } from 'fbemitter';
import {
  ClientIncomingMessages,
  ClientOutgoingMessageTypes,
  MessageByType,
} from '@wix/user-feedback-common';

interface TransportOptions {
  targetOrigin: string;
}

const getEventData = (event: MessageEvent): ClientIncomingMessages =>
  event.data;

export class IframeTransport {
  private readonly messagesEmitter: EventEmitter = new EventEmitter();

  constructor(
    private readonly targetFrame: HTMLIFrameElement,
    private readonly options: TransportOptions,
  ) {
    window.addEventListener('message', this.handleMessage);
  }

  private getTargetOrigin(): string {
    return this.options.targetOrigin;
  }

  private readonly handleMessage = (event: MessageEvent) => {
    if (!this.getTargetOrigin().includes(event.origin)) {
      return;
    }
    const { type, data }: ClientIncomingMessages = getEventData(event);
    this.messagesEmitter.emit(type, data);
  };

  public destroy() {
    this.messagesEmitter.removeAllListeners();
    window.removeEventListener('message', this.handleMessage);
  }

  public sendMessage<M extends ClientIncomingMessages>(message: M) {
    const { contentWindow } = this.targetFrame;
    if (!contentWindow) {
      throw Error(
        `can not send message "${JSON.stringify(message)}", no contentWindow`,
      );
    }
    contentWindow.postMessage(message, this.getTargetOrigin());
  }

  public waitForMessage<
    T extends ClientOutgoingMessageTypes,
    M extends MessageByType<T> = MessageByType<T>
  >(postMessageType: T): Promise<M['data']> {
    return new Promise((resolve) => {
      this.messagesEmitter.once(postMessageType, (data) => {
        resolve(data);
      });
    });
  }

  public addListener<
    T extends ClientOutgoingMessageTypes,
    M extends MessageByType<T> = MessageByType<T>
  >(postMessageType: T, listener: (payload: M['data']) => void) {
    this.messagesEmitter.addListener(postMessageType, listener);
  }
}

export const createTransport = (
  targetFrame: HTMLIFrameElement,
  options: TransportOptions,
): IframeTransport => {
  return new IframeTransport(targetFrame, options);
};
