import { BiDefaultParams } from '@wix/user-feedback-common';
import { biLogger } from './bi-logger';

interface BiExposeResponseParams extends BiDefaultParams {
  isAllowed: boolean;
  blockReason?: string;
}

export const trackExposureResponse = (params: BiExposeResponseParams) => {
  // tslint:disable-next-line no-floating-promises
  biLogger.log({
    ...params,
    evid: 815,
  });
};
