export type MutedError<T> =
  | {
      isError: true;
      error: any;
    }
  | {
      isError: false;
      result: T;
    };

export function mutePromiseError<T>(
  rawPromise: Promise<T>,
): Promise<MutedError<T>> {
  return rawPromise.then(
    (result: T) => {
      const r: MutedError<T> = {
        isError: false,
        result,
      };
      return r;
    },
    (error) => {
      const r: MutedError<T> = {
        isError: true,
        error,
      };
      return r;
    },
  );
}

export function unmutePromiseError<T>(
  mutedPromise: Promise<MutedError<T>>,
): Promise<T> {
  return mutedPromise.then((mutedResult) => {
    if (mutedResult.isError) {
      return Promise.reject(mutedResult.error);
    }

    return Promise.resolve(mutedResult.result);
  });
}
