import { biLogger } from './bi-logger';
import { BiDefaultParams, CloseReason } from '@wix/user-feedback-common';
import { CloseReasonType } from '../constants';

export function closeReasonToCloseReasonType(
  reason: CloseReason,
): CloseReasonType {
  switch (reason) {
    case CloseReason.Canceled:
      return CloseReasonType.Canceled;
    case CloseReason.FinishedFlow:
      return CloseReasonType.FinishedFlow;
    case CloseReason.CanceledDueToError:
      return CloseReasonType.CanceledDueToError;
    default:
      return CloseReasonType.Canceled;
  }
}

interface BiCloseFrameMessageReceivedParams extends BiDefaultParams {
  closeReason: CloseReasonType;
}

export const trackCloseFrameMessageReceived = (
  params: BiCloseFrameMessageReceivedParams,
) => {
  // tslint:disable-next-line no-floating-promises
  biLogger.log({
    ...params,
    evid: 846,
  });
};
