import webBiLogger from '@wix/web-bi-logger';
import { parseMetasiteId } from '../utils/opener.utils';

type LoggerDefaults = Partial<{
  src: number;
  evid: number;
}>;

export function createBiLogger(defaults: LoggerDefaults = {}) {
  const logger = webBiLogger
    .factory({ endpoint: 'feedback-service' })
    .updateDefaults(defaults)
    .logger();

  return {
    log: (params?: any, ...optionalParams: any[]) => {
      if (params && params.metasiteId) {
        params = {
          ...params,
          metasiteId: parseMetasiteId(params.metasiteId),
        };
      }

      return logger.log(params, ...optionalParams);
    },
  };
}
