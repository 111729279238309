import { ShowOptions, ShowOptionsComplete } from '../common.types';

export enum QuestionStateType {
  None = 'none',
  Opening = 'opening',
  Shown = 'shown',
}

export interface EmptyQuestionState {
  state: QuestionStateType.None;
}

export interface OpeningQuestionState {
  state: QuestionStateType.Opening;
  showOptions: ShowOptions;
  showOptionsComplete: ShowOptionsComplete;
  sessionId: string;
}

export interface ShownQuestionState {
  state: QuestionStateType.Shown;
  showOptions: ShowOptions;
  showOptionsComplete: ShowOptionsComplete;
  sessionId: string;
}

export type QuestionState =
  | EmptyQuestionState
  | OpeningQuestionState
  | ShownQuestionState;
