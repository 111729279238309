import { OpenerBreadcrumb } from '@wix/user-feedback-common';

export interface Breadcrumb {
  message: string;
  data?: any;
}

let list: OpenerBreadcrumb[] = [];

export function captureBreadcrumb(breadcrumb: Breadcrumb) {
  list.push({
    ...breadcrumb,
    time: new Date(),
  });
}

export function resetBreadcrumbs() {
  list = [];
}

export function getBreadcrumbs(): OpenerBreadcrumb[] {
  return [...list];
}
