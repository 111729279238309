const iFrameStyle = `position: fixed; top: 0; left: 0; z-index: 6001; width: 100%; height: 100%; border: 0; background: rgba(255, 255, 255, 0.8);`;

export class IFrameWrapper {
  private readonly iframe: HTMLIFrameElement;

  constructor(url: string, title: string, appendTo: HTMLElement) {
    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.id = 'userFeedback';
    iframe.title = title;
    iframe.setAttribute('style', iFrameStyle);
    iframe.style.display = 'none';
    appendTo.appendChild(iframe);

    this.iframe = iframe;
  }

  show() {
    this.iframe.style.display = 'block';
    const { contentWindow } = this.iframe;
    if (contentWindow && contentWindow.focus) {
      contentWindow.focus();
    }
  }

  hide() {
    this.iframe.style.display = 'none';
  }

  destroy() {
    const { parentNode } = this.iframe;
    if (parentNode) {
      parentNode.removeChild(this.iframe);
    }
  }

  get wrappedIFrame(): HTMLIFrameElement {
    return this.iframe;
  }
}
