// TODO 3 out of 4 items are the same as in `CloseReason` enum. Maybe there is sense kinda extend this enum from `CloseReason`?
export enum CloseReasonType {
  FinishedFlow = 'finished-flow',
  Canceled = 'canceled',
  CanceledDueToError = 'canceled-due-to-error',
  ClosedByOpener = 'closed-by-opener',
}

export enum OpenerEventTypes {
  Opened = 'opened',
  Closed = 'closed',
  Error = 'error',
}
